import React from "react";
import Invest from "../images/soil.avif";

const AgriInvestment = () => {
  return (
    <div className="bg-white pt-20 font-poppins">
      <div
        className="relative bg-cover bg-center"
        style={{ backgroundImage: "url(/farmland.avif)" }}
      >
        <div className="text-white px-4 bg-black bg-opacity-60 font-poppins h-80 flex flex-col items-center justify-center">
          <h1 className="text-2xl mb-2">AGRI INVESTMENT</h1>
          <h2 className="text-5xl mb-2">
            Invest in prime agricultural assets that return 10%+ p.a.
          </h2>
          <p>
            <span className="text-[#a86538] text-sm">home</span> {">"} agri
            investment
          </p>
        </div>
      </div>
      {/* <div className="max-w-7xl mx-auto flex py-16 px-4 sm:px-6 lg:py-20 lg:px-8">
        <div>
          <h2 className="text-2xl font-bold text-gray-900">
            The American agricultural sector offers a key diversifier for
            investors seeking sustainable and long-term income and capital
            returns.
          </h2>
          <p className="mt-4 text-lg text-gray-500">
            Maturation Farms recognises that farmland when well managed and held
            over long periods of time, has proven to be a unique asset class
            that has delivered superior returns with relatively less risk and
            low correlation to traditional asset classes adding overall
            diversification to most portfolios. Investors globally can access
            agriculture assets through a range of Maturation Farms investment
            opportunities from tailored portfolio construction to complete end
            to end management solutions.
          </p>
        </div>
        <img
          className="mt-8 w-full h-auto"
          src={Invest}
          alt="Agricultural land"
        />
      </div> */}
      <div className="max-w-7xl mx-auto py-16 px-4 flex flex-col md:flex-row items-center justify-between">
        <div className="md:w-1/2 md:pr-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">
            The American agricultural sector offers a key diversifier for
            investors seeking sustainable and long-term income and capital
            returns.
          </h2>
          <p className="mt-4 text-lg text-gray-500">
            Maturation Farms recognises that farmland when well managed and held
            over long periods of time, has proven to be a unique asset class
            that has delivered superior returns with relatively less risk and
            low correlation to traditional asset classes adding overall
            diversification to most portfolios. Investors globally can access
            agriculture assets through a range of Maturation Farms investment
            opportunities from tailored portfolio construction to complete end
            to end management solutions.
          </p>
        </div>
        <div className="md:w-1/2 mt-8 md:mt-0">
          <img src={Invest} alt="invest in a farm" className="w-full" />
        </div>
      </div>
    </div>
  );
};

export default AgriInvestment;
