import React from "react";
import Logo from "../images/logo.png";

const Footer = () => {
  return (
    <footer
      id="footer"
      className="bg-[#0f213c] text-white pt-6 pb-2 px-4 font-inter"
    >
      <div className="max-w-7xl mx-auto flex flex-col md:flex-row items-center md:items-start justify-between">
        {/* Logo and Website Name */}
        <div className="flex items-center mb-4 md:mb-0 invisible">
          <img src={Logo} alt="Logo" className="h-20 mr-2" />
          <span className="text-lg font-semibold">
            Maturation <br /> Farm
          </span>
        </div>

        {/* Company Details */}
        <div className="text-center md:text-left">
          <p className="mb-8 font-semibold hidden md:flex">Our Company</p>
          <p className="mb-2">18895 Bollinger Canyon Rd,</p>
          <p>San Ramon, CA 94583 USA.</p>
          <p className="my-2">maturationfarm@outlook.com</p>
          <p className="my-2">+1(925)3029247</p>
        </div>

        {/* Contact Us */}
        <div className="text-center md:text-left">
          <p className="mb-8 font-semibold hidden md:flex">Contact Us</p>
          <p className="grid">
            <a href="mailto:maturationfarm@outlook.com" className="underline mb-2">
              email
            </a>
            {/* <a className="underline" href="tel:+1(925)3029247">
              Call Us
            </a> */}
          </p>
        </div>
      </div>
      <p className="flex justify-center lg:justify-end text-right text-sm mt-10">
        Copyright &#169; &nbsp;{" "}
        <span className="font-poppins"> Maturation Farms 2024</span>
      </p>
    </footer>
  );
};

export default Footer;
