import React from "react";
import Card1 from "../images/farm2.avif";
import Card2 from "../images/farm3.avif";
import Card3 from "../images/farm4.avif";

const CardsSection = () => {
  return (
    <section className="py-20 bg-[#f7f7f7] px-4 font-poppins">
      <h1 className="text-4xl text-center mb-20">Insights</h1>
      <div className="max-w-7xl mx-auto flex flex-wrap justify-center">
        {/* Card 1 */}
        <div className="w-full sm:w-1/2 md:w-1/3 px-4 mb-8">
          <div className="bg-white shadow-lg rounded-lg overflow-hidden">
            <img
              src={Card1}
              alt="Card1"
              className="w-full h-56 object-cover object-center"
            />
            <div className="p-4">
              <h3 className="text-2xl text-[#a86538] mb-2">
                Have we forgotten productivity in pursuit of sustainability?
              </h3>
              <p>
                These notes have been put together to support the conversation
                around investing in agriculture and sustainability. The starting
                point is productivity given that it underpins the ability of the
                sector to thrive.
              </p>
            </div>
          </div>
        </div>

        {/* Card 2 */}
        <div className="w-full sm:w-1/2 md:w-1/3 px-4 mb-8">
          <div className="bg-white shadow-lg rounded-lg overflow-hidden">
            <img
              src={Card2}
              alt="Card2"
              className="w-full h-56 object-cover object-center"
            />
            <div className="p-4">
              <h3 className="text-2xl text-[#a86538] mb-2">
                Holistic approach to farm management boosts performance
              </h3>
              <p>
                Maturation Farm's focus on sustainability has led to outstanding
                results on the farms projects and property we manage
              </p>
            </div>
          </div>
        </div>

        {/* Card 3 */}
        <div className="w-full sm:w-1/2 md:w-1/3 px-4 mb-8">
          <div className="bg-white shadow-lg rounded-lg overflow-hidden">
            <img
              src={Card3}
              alt="Card3"
              className="w-full h-56 object-cover object-center"
            />
            <div className="p-4">
              <h3 className="text-2xl text-[#a86538] mb-2">
                Maturation farms to wind up American Agricultural Lease Fund
                four years early - Agri Investor exclusive
              </h3>
              <p>
                Investors voted to wind up the fund due to the rise in farmland
                values since its launch, which have compressed rental yields and
                offer a chance to secure strong exit prices now.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CardsSection;
