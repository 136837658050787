import AgriInvestment from "../components/AgriInvestment";
import CardsSection from "../components/Cards";
import InvestProducts from "../components/Investproduct";
import DeploymentModels from "../components/models";

export default function Investment() {
  return (
    <div className="relative font-inter">
      <AgriInvestment />
      <DeploymentModels />
      <InvestProducts />
      <CardsSection />
    </div>
  );
}
