import { useState, useEffect, useRef } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AiOutlineArrowUp } from "react-icons/ai";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/home";
import Agri from "./pages/invest";

export default function App() {
  // scroll to top button
  const [position, setPosition] = useState({ top: 0, left: 0 });
  useEffect(() => {
    window.scroll({
      top: position.top,
      left: position.left,
      behavior: "smooth",
    });
  });
  const scrollTop = useRef();
  useEffect(() => {
    window.addEventListener("scroll", (e) => {
      window.scrollY > 200
        ? (scrollTop.current.style.display = "inline-block")
        : (scrollTop.current.style.display = "none");
    });
  });

  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/invest" element={<Agri />} />
        </Routes>
        <Footer />
      </BrowserRouter>
      <span
        onClick={() =>
          setPosition({ ...position, position: { top: 0, left: 0 } })
        }
        className="bg-primary p-2 rounded-md fixed hidden bottom-[100px] right-8 "
        ref={scrollTop}
      >
        <AiOutlineArrowUp className="text-white font-bold w-8 h-auto" />
      </span>
    </div>
  );
}
