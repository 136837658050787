import About from "../components/About";
import Banner from "../components/banner";
import InfoSection from './../components/InfoSection';
import Products from './../components/Products';
import CardsSection from './../components/Cards';


export default function Home() {
  return (
    <div className="relative font-inter">
      <Banner />
      <InfoSection />
      <About />
      <Products />
      <CardsSection />
    </div>
  );
};
