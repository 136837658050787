import React from "react";

const Banner = () => {
  return (
    <div
      className="relative md:h-screen flex items-center justify-center bg-cover bg-center"
      style={{ backgroundImage: `url('/farm4.avif')` }}
    >
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="absolute z-10 text-center text-white">
        <h1 className="text-5xl font-semibold tracking-wider">
          Sustainable Superior Results
        </h1>
        <p className="mt-4 text-lg">
          Maturation farms acquires and manages prime agricultural assets on
          behalf of private and institutional investors.
        </p>
      </div>
    </div>
  );
};

export default Banner;
