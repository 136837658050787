import React from "react";
import aboutImage from "../images/banner-2.jpg";

const About = () => {
  return (
    <section id="about" className="bg-[#2c3d5b] py-12 px-4">
      <div className="max-w-7xl mx-auto flex flex-col md:flex-row items-center justify-between text-white">
        <div className="md:w-1/2 md:pr-8">
          <h2 className="text-4xl font-bold mb-4">Our Story</h2>
          <p className="mb-4 text-xl">
            Maturation farm is a leading provider of plantation and farm
            investment opportunities located in San Ramon, California. We
            specialize in sustainable agricultural projects that offer lucrative
            returns and promote environmental stewardship. Our portfolio
            includes investments in diverse crops, ensuring a balanced and
            profitable investment strategy.
          </p>
          <p className="text-xl">
            With over 20 years of experience in the agricultural sector, our
            team of experts is dedicated to identifying and managing
            high-potential farm investments that meet your financial goals and
            ethical values. At Maturation Farm, we prioritize transparency,
            sustainability, and community development, partnering with local
            farmers to enhance productivity and livelihoods.
          </p>
        </div>
        <div className="md:w-1/2 mt-8 md:mt-0">
          <img src={aboutImage} alt="invest in a farm" className="w-full" />
        </div>
      </div>
    </section>
  );
};

export default About;
