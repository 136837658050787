import React from "react";
import ProductCard from "./ProductCard";
import Product1 from "../images/bulldozing.avif";
import Product2 from "../images/skyandsoil.avif";

const Products = () => {
  const products = [
    {
      image: Product1,
      title: "Self Managed Accounts(SMAs)",
    },
    {
      image: Product2,
      title: "Funds",
    },
  ];

  return (
    <div className="py-20">
      <h1 className="text-3xl font-semibold text-center mb-20">Our Products</h1>
      <div className="max-w-6xl mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {products.map((product, index) => (
            <ProductCard
              key={index}
              image={product.image}
              title={product.title}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Products;
