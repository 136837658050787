import React from "react";

const InvestProducts = () => {
  return (
    <section>
      <div className="bg-gray-50 py-16 px-4 sm:px-6 lg:px-8 font-poppins">
        <div className="max-w-7xl mx-auto">
          <h2 className="text-3xl leading-8 text-gray-900 sm:text-4xl">
            Our Products
          </h2>
          <div className="mt-10 space-y-8">
            <div className="bg-gray-100 py-10 px-6 rounded-lg ">
              <h3 className="text-2xl leading-6 text-gray-900">
                Separately Managed Accounts
              </h3>
              <p className="mt-4 text-lg text-gray-700">
                The separately managed account (SMA) option provides
                high-net-worth investors with a superior level of customisation.
                It is designed for those seeking income and capital growth from
                diversified investments.
              </p>
            </div>
            <div className="bg-gray-100 py-10 px-6 rounded-lg ">
              <h3 className="text-2xl leading-6 text-gray-900">Funds</h3>
              <p className="mt-4 text-lg text-gray-700">
                Institutional investors, with the assistance of Maturation
                farms, can buy high-quality and diverse American agricultural
                assets. The focus is sustainable income and capital growth along
                with diversification across livestock, irrigated and dryland
                crops. The income and growth targets are delivered with moderate
                levels of volatility compared to other asset classes such as
                direct equities.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#a86538] h-24 flex items-center justify-center">
        <a href="#footer" className="border-2 border-white text-white hover:border-blue-950 hover:bg-blue-950 text-sm font-semibold px-8 py-2">
          CONTACT US
        </a>
      </div>
    </section>
  );
};

export default InvestProducts;
