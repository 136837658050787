import React from "react";

const DeploymentModels = () => {
  return (
    <div className="bg-gray-50 py-24 px-4 sm:px-6 lg:px-8 font-poppins">
      <div className="max-w-7xl mx-auto">
        <h2 className=" text-3xl leading-8 text-gray-900 sm:text-4xl">
          Deployment Models
        </h2>
        <p className="mt-4 text-gray-700 lg:mx-auto">
          There are two models available to investors – active (buy and operate)
          or passive (buy and lease)
        </p>
        <div className="mt-10">
          <div className="flex flex-col lg:flex-row justify-center gap-8">
            <div className="lg:w-1/2">
              <h3 className="text-2xl leading-6 text-gray-900">
                Buy and Operate
              </h3>
              <ul className="mt-4 text-lg text-gray-700 space-y-2">
                <li>
                  – Opportunities to change use, develop and improve
                  productivity
                </li>
                <li>
                  – Higher return on land due to infrastructure development
                </li>
                <li>
                  – More volatile due to direct exposure to operational risks
                </li>
              </ul>
            </div>
            <div className="lg:w-1/2">
              <h3 className="text-2xl leading-6 text-gray-900">
                Buy and Lease
              </h3>
              <ul className="mt-4 text-lg text-gray-700 space-y-2">
                <li>– Assets are leased to third-party operators</li>
                <li>– Offers stable cash returns with lower risk</li>
                <li>– Portfolio and tenants are managed by Maturation farms</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeploymentModels;
