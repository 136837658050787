import React from "react";

const InfoSection = () => {
  return (
    <div className="bg-[#a86538] h-24 flex items-center justify-center">
      <button className="border-2 border-white text-white hover:border-blue-950 hover:bg-blue-950 text-sm font-semibold px-8 py-2">
        FIND OUT MORE
      </button>
    </div>
  );
};

export default InfoSection;
