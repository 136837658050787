import React, { useState } from "react";
import Logo from "../images/log0-new.png";
import { HiOutlineMenu } from "react-icons/hi";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-[#0f213c] px-4 z-50 fixed w-full">
      <div className="max-w-7xl mx-auto flex justify-between items-center">
        <div className="flex items-center">
          <img src={Logo} alt="Logo" className="h-20 mr-2" />
          <a
            href="/"
            className="text-white font-s text-xl text-left font-poppins"
          >
            Maturation <br /> Farm
          </a>
        </div>
        <div className="hidden md:block">
          <ul className="flex space-x-4">
            <li>
              <a
                href="/"
                className="text-white hover:bg-gray-700 px-3 py-2 rounded"
              >
                Home
              </a>
            </li>
            <li>
              <a
                href="/#about"
                className="text-white hover:bg-gray-700 px-3 py-2 rounded"
              >
                About
              </a>
            </li>
            <li>
              <a
                href="/invest"
                className="text-white hover:bg-gray-700 px-3 py-2 rounded"
              >
                Agri investments
              </a>
            </li>
            <li>
              <a
                href="/#footer"
                className="text-white text-sm font-poppins bg-[#a86538] hover:bg-gray-700 px-7 py-2"
              >
                CONTACT
              </a>
            </li>
          </ul>
        </div>
        <div className="block md:hidden">
          <button
            onClick={toggleNavbar}
            className="text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 px-3 py-2 rounded"
          >
            <HiOutlineMenu />
          </button>
        </div>
      </div>
      <div className={`${isOpen ? "block" : "hidden"} md:hidden`}>
        <ul className="mt-2 space-y-1">
          <li>
            <a
              href="/"
              className="text-white hover:bg-gray-700 px-3 py-2 rounded block"
            >
              Home
            </a>
          </li>
          <li>
            <a
              href="/#about"
              className="text-white hover:bg-gray-700 px-3 py-2 rounded block"
            >
              About
            </a>
          </li>
          <li>
            <a
              href="/#footer"
              className="text-white hover:bg-gray-700 px-3 py-2 rounded block"
            >
              Contact
            </a>
          </li>
          <li>
            <a
              href="/invest"
              className="text-white hover:bg-gray-700 px-3 py-2 rounded block"
            >
              Agri investments
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
